import {Auth} from 'aws-amplify';
import {GraphQLClient, gql} from 'graphql-request';
import {useQuery} from 'react-query';

import isJsonString from '../utils/utils';

const endpoint = process.env.REACT_APP_API_ENDPOINT;
const apiKey = process.env.REACT_APP_API_KEY;

const getPublicAlbums = async () => {
  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      'x-api-key': apiKey,
    },
  });

  const data = await graphQLClient.request(
      gql`
      query {
        allAlbums {
          albums {
            id
            title
            description
            cover
          }
          nextToken
        }
      }
      `,
  );
  const transformedAlbums = data.allAlbums.albums.map((album) => {
    if (isJsonString(album.cover)) {
      album.cover = JSON.parse(album.cover);
    } else {
      album.cover = '';
    }
    return album;
  });
  return transformedAlbums;
};


const getPrivateAlbums = async () => {
  const session = await Auth.currentSession();

  const headers = {
    'Authorization': session.getAccessToken().getJwtToken(),
  };

  const graphQLClient = new GraphQLClient(endpoint, {
    headers,
  });

  const data = await graphQLClient.request(
      gql`
      query {
        allPrivateAlbums {
          albums {
            id
            title
            description
            cover
            isPublic
          }
          nextToken
        }
      }
      `,
  );
  const transformedAlbums = data.allPrivateAlbums.albums.map((album) => {
    if (isJsonString(album.cover)) {
      album.cover = JSON.parse(album.cover);
    } else {
      album.cover = '';
    }
    return album;
  });
  return transformedAlbums;
};


const placeholderAlbums = [
  {
    id: '',
    title: '',
    description: '',
    cover: {
      id: '',
      path: '',
    },
    isPublic: true,
  },
];

/**
 *
 * @return {*}
 */
function useAlbums() {
  return useQuery('albums', getPublicAlbums, {
    placeholderData: placeholderAlbums,
    retry: false,
  });
}

/**
 * @param {*} headers
 * @return {*}
 */
function usePrivateAlbums(headers) {
  return useQuery('privateAlbums', getPrivateAlbums, {
    retry: false,
  });
}

export {
  useAlbums,
  usePrivateAlbums,
};
