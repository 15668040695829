/* eslint-disable require-jsdoc */
import React, {useEffect, useMemo, useRef} from 'react';

import {EyeOutlined} from '@ant-design/icons';
import {Typography, Divider} from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import PhotoAlbum from 'react-photo-album';

import classes from './Photo.module.css';

const {Paragraph, Title} = Typography;

const Photo = (props) => {
  const {photos, title, description} = props;

  const renderPhoto = ({layoutOptions, photo, layout, wrapperStyle, renderDefaultPhoto}) => {
    const onClickPhoto = (e) => {
      layoutOptions.onClick(e, photo, layout.index);
    };
    return (
      <div className={classes.imageContainer} style= {{...wrapperStyle}} onClick={onClickPhoto}>
        {renderDefaultPhoto({wrapped: true})}
        <div className={classes.previewMask}>
          <div className={classes.previewMaskInfo} >
            <EyeOutlined style={{marginRight: '5px'}} />
            Voir
          </div>
        </div>
      </div>
    );
  };

  // Get content size
  const mainElement = useMemo(() => document.querySelector('main'), []);
  useEffect(() => {
    if (mainElement) {
      // If we have less images than what the page allow, we pre-load
      if (mainElement.scrollHeight < window.innerHeight) {
        if (photos.length !== 0 && props.totalPhotos) {
          props.morePhotos();
        }
      }
    }
  }, [photos]);

  return (
    <Typography>
      <Divider />
      <Title>{title}</Title>
      <Paragraph italic={true}>{description}</Paragraph>
      {photos && (
        <InfiniteScroll
          dataLength={photos.length}
          next={async () => {
            await props.morePhotos();
          }}
          hasMore={props.totalPhotos}
          loader={<h4>Loading...</h4>}
        >
          {photos.map((gallery, index) => (
            <PhotoAlbum
              key={index}
              photos={gallery}
              layout="rows"
              spacing={5}
              targetRowHeight={(containerWidth) => {
                if (containerWidth < 400) return 500;
                return 400;
              }}
              onClick={(event, photo, index) => {
                const albumIndex = event.target.parentElement.parentElement.parentElement.getAttribute('data-id');
                props.openPreview(albumIndex, index);
              }}
              renderPhoto={renderPhoto}
              componentsProps={{containerProps: {'style': {paddingBottom: 5}, 'data-id': index}}}
            />
          ))}
        </InfiniteScroll>
      )}
      {!photos && <Paragraph>No photos in this album.</Paragraph>}
    </Typography>
  );
};

export default Photo;
