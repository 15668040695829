import React, {useState} from 'react';

import {Route, Switch, Redirect} from 'react-router-dom';

import GlobalLayout from './components/Layout/GlobalLayout';
import NotFound from './components/NotFound';
import PrivateRoute from './components/PrivateRoute';
import AlbumDetailPage from './pages/AlbumDetailPage';
import AlbumsPage from './pages/AlbumsPage';
import HomePage from './pages/HomePage';

/**
 *
 * @return {*}
 */
function App() {
  const [currentAlbum, setCurrentAlbum] = useState(false);

  return (
    <Switch>
      <PrivateRoute path="/dashboard"/>
      <GlobalLayout breadcrumbProps={currentAlbum}>
        <Switch>
          <Route path='/' exact>
            <HomePage />
          </Route>
          <Route path='/albums' exact>
            <AlbumsPage />
          </Route>
          <Route path='/albums/:albumId'>
            <AlbumDetailPage currentAlbumCallback={setCurrentAlbum}/>
          </Route>
          <Route path='/not-found'>
            <NotFound />
          </Route>
          <Route path='*'>
            <Redirect to='/not-found' />
          </Route>
        </Switch>
      </GlobalLayout>
    </Switch>
  );
}

export default App;
