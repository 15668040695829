import React from 'react';

import {Skeleton} from 'antd';
import {Link} from 'react-router-dom';

import classes from './ImageHover.module.css';

const ImageHover = (props) => {
  const {imageAlt, imageUrl, imageSrcSet, imageSizes, textLinkOverlay, textOverlay, linkOverlay} = props;

  let img = <img src={imageUrl} alt={imageAlt} srcSet={imageSrcSet} sizes={imageSizes}/>;
  if (imageUrl === '') {
    img = <Skeleton.Image />;
  }

  return (
    <Link to={linkOverlay} className={classes.hovereffect}>
      {img}
      <div className={classes.overlay}>
        <h2>{textOverlay}</h2>
        <div>{textLinkOverlay}</div>
      </div>
    </Link>
  );
};

export default ImageHover;


