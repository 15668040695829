import React, {useState} from 'react';

import {withAuthenticator} from '@aws-amplify/ui-react';
import {Layout, Row, Col, Menu, Tabs} from 'antd';
import {Amplify} from 'aws-amplify';

import AdminAlbum from './Admin/AdminAlbum';
import AdminPhoto from './Admin/AdminPhoto';
import UpdateAlbum from './Admin/UpdateAlbum';
import classes from './PrivateRoute.module.css';

import '@aws-amplify/ui-react/styles.css';

const {Header, Content} = Layout;

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AWS_REGION,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_USERPOOL_ID,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_USERPOOL_CLIENT_ID,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  },
});

const PrivateRoute = ({signOut, user}) => {
  const [updateAlbum, setUpdateAlbum] = useState(false);

  const handleUpdateAlbum = (album) => {
    setUpdateAlbum(album);
  };

  const handleEndUpdateAlbum = () => {
    setUpdateAlbum(false);
  };

  const handleSignOut = (e) => {
    if (e.key === '1') {
      signOut();
    }
  };


  let content;
  if (updateAlbum !== false) {
    content = <Col span={24}><UpdateAlbum album={updateAlbum} endUpdateCallback={handleEndUpdateAlbum}/></Col>;
  } else {
    content = <Col span={24}><Tabs
      defaultActiveKey="1"
      items={[
        {
          label: `Album admin`,
          key: '1',
          children: <AdminAlbum updateAlbumCallback={handleUpdateAlbum} />,
        },
        {
          label: `Photo admin`,
          key: '2',
          children: <AdminPhoto updateAlbumCallback={handleUpdateAlbum} />,
        },
      ]}
    /></Col>;
  }

  return (
    <Layout>
      <Header>
        <div className={classes.logo}>Admin Panel</div>
        <Menu
          theme="dark"
          mode="horizontal"
          onClick={handleSignOut}
          items={
            [
              {
                label: (
                  <a href="/" target="_blank" rel="noopener noreferrer">
                    Homepage
                  </a>
                ),
                key: 0,
              },
              {
                key: 1,
                label: 'Sign Out',
              },
            ]
          }
        />
      </Header>

      <Content className={classes['site-layout-content']}>
        <Row>
          {content}
        </Row>
      </Content>
    </Layout>
  );
};

export default withAuthenticator(PrivateRoute, {hideSignUp: true});


