import React, {useEffect, useState, useRef} from 'react';

import {Helmet} from 'react-helmet';
import {useQuery} from 'react-query';
import {useParams, Redirect} from 'react-router';
import Lightbox from 'yet-another-react-lightbox';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/styles.css';

import Photo from '../components/Photo/Photo';
import {getAlbumByName, getPicturesForChunk} from '../hooks/useAlbum';
import classes from './AlbumDetailPage.module.css';

const AlbumDetailPage = (props) => {
  const params = useParams();
  const [chunkIdx, setChunkIdx] = useState(0);
  const [pictures, setPictures] = useState({photos: [], slides: []});
  const [previewIndex, setPreviewIndex] = useState(-1);

  const albumId = params.albumId;

  const previousAlbum = useRef(albumId);

  const {
    isLoading,
    isError,
    isSuccess,
    data,
  } = useQuery({
    queryKey: ['album', albumId],
    queryFn: () => getAlbumByName(albumId),
    retry: false,
  });

  useEffect(() => {
    if (albumId !== previousAlbum) {
      previousAlbum.current = albumId;
      if (isSuccess) {
        props.currentAlbumCallback({
          id: albumId,
          title: data.title,
        },
        );
      }
    }
  }, [albumId, isSuccess]);

  useEffect(async () => {
    if (data && chunkIdx !== data.chunkList.length) {
      const pics = await getPicturesForChunk(data.chunkList[chunkIdx]);

      const slides = pics.map(({src, width, height}) => (
        {
          src,
          width,
          height,
          loading: 'lazy',
        }),
      );

      const newPhotos = [
        ...(pictures.photos),
        pics,
      ];

      const newSlides = [
        ...(pictures.slides),
        slides,
      ];

      setPictures({photos: newPhotos, slides: newSlides});
    }
  }, [isSuccess, chunkIdx]);

  const loadMore = () => {
    const newIdx = chunkIdx + 1;
    if (newIdx < data.chunkList.length ) {
      setChunkIdx(newIdx);
    }
  };

  const loadAll = async () => {
    const slides = [];
    const newPics = [];
    for (let index = chunkIdx + 1; index < data.chunkList.length; index++) {
      const pics = await getPicturesForChunk(data.chunkList[index]);
      const chunkSlides = pics.map(({src, width, height}) => (
        {
          src,
          width,
          height,
          loading: 'lazy',
        }),
      );
      newPics.push(pics);
      slides.push(...chunkSlides);
    }
    const newPhotos = [
      ...pictures.photos,
      ...newPics,
    ];

    const newSlides = [
      ...pictures.slides,
      slides,
    ];

    setPictures({photos: newPhotos, slides: newSlides});
    setChunkIdx(data.chunkList.length);
  };

  const openPreview = async (albumIndex, index) => {
    const calculatedIndex = pictures.photos[0].length * albumIndex + index;
    setPreviewIndex(calculatedIndex);
    if (chunkIdx < data.chunkList.length) {
      await loadAll();
    }
  };

  if (isLoading) {
    return (<div>Loading...</div>);
  }

  if (isError) {
    return (<Redirect to='/not-found' />);
  }
  return (
    <>
      <Helmet>
        <title>{data.title} - Album - Photos - Desrumaux</title>
        <meta
          name="description"
          content={`Album page of ${data.title}`}
        />
      </Helmet>
      <Photo title={data.title} photos={pictures.photos} description={data.description} totalPhotos={data.chunkList[chunkIdx + 1] ? true : false} morePhotos={loadMore} openPreview={openPreview}/>
      <Lightbox
        open={previewIndex >= 0}
        index={previewIndex}
        carousel={{finite: true}}
        close={() => setPreviewIndex(-1)}
        slides={pictures.slides.flat()}
        plugins={[Zoom]}
      />
      {data.chunkList[chunkIdx + 1] && <div className={classes.more}>More</div>}
    </>
  );
};

export default AlbumDetailPage;
