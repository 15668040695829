import {Auth} from 'aws-amplify';
import {GraphQLClient, gql} from 'graphql-request';

const sizeMapping = [
  {
    path: 'large',
    width: 700,
  },
  {
    path: 'medium',
    width: 500,
  },
  {
    path: 'small',
    width: 300,
  },
];

const endpoint = process.env.REACT_APP_API_ENDPOINT;
const apiKey = process.env.REACT_APP_API_KEY;
const RESIZED_PHOTOS = process.env.REACT_APP_BUCKET_URL;

const getAlbumByName = async (albumName, cursor) => {
  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      'x-api-key': apiKey,
    },
  });
  const query = gql`query ($albumId: String!) {
    Album(albumId: $albumId) {
      id
      title
      description
      photos
    }
  }`;
  const variables = {
    albumId: albumName,
  };
  const data = await graphQLClient.request(query, variables);
  const album = data.Album;

  if (album.photos) {
    const chunkList = [];
    const LIMIT = 8;
    for (let i = 0; i < album.photos.length; i += LIMIT) {
      const chunk = album.photos.slice(i, i + LIMIT);
      chunkList.push(chunk);
    }
    album.chunkList = chunkList;
  }
  return album;
};

const getPicturesForChunk = async (chunk) => {
  const graphQLClient = new GraphQLClient(endpoint, {
    headers: {
      'x-api-key': apiKey,
    },
  });

  const transformedPhotosPromises = chunk.map(async function(image) {
    const query = gql`query ($photoId: String!) {
          Photo(photoId: $photoId) {
            id
            name
            path
            height
            width
          }
        }`;
    const variables = {
      photoId: image,
    };
    const data = await graphQLClient.request(query, variables);
    const imageData = data.Photo;

    const images = sizeMapping.map((size) => {
      const splitKey = imageData.path.split('.');
      if (imageData.width > imageData.height) {
        return {
          src: `${RESIZED_PHOTOS}/${splitKey[0]}-${size.path}.${splitKey[1]}`,
          width: size.width,
        };
      } else {
        const width = Math.round(size.width / 3 * 2);
        return {
          src: `${RESIZED_PHOTOS}/${splitKey[0]}-${size.path}.${splitKey[1]}`,
          width: width,
        };
      }
    });

    return {
      src: `${RESIZED_PHOTOS}/${imageData.path}`,
      images: images,
      width: imageData.width,
      height: imageData.height,
    };
  });
  const photosResult = await Promise.allSettled(transformedPhotosPromises);
  return photosResult.reduce((acc, crt) => acc.concat(crt.value), []);
};

const getAlbumById = async (albumId) => {
  const session = await Auth.currentSession();

  const headers = {
    'Authorization': session.getAccessToken().getJwtToken(),
  };

  const graphQLClient = new GraphQLClient(endpoint, {
    headers,
  });

  const query = gql`query ($albumId: String!) {
    Album(albumId: $albumId) {
      id
      title
      description
      photos
      cover
    }
  }`;
  const variables = {
    albumId,
  };
  const data = await graphQLClient.request(query, variables);
  return data.Album;
};


export {
  getAlbumByName,
  getAlbumById,
  getPicturesForChunk,
};
