import React, {useState} from 'react';

import {CloseSquareFilled, CheckCircleFilled, FileImageFilled} from '@ant-design/icons';
import {Button, Input, Form, Card, Typography, Switch, Space, List, Divider} from 'antd';
import {Auth} from 'aws-amplify';
import {useMutation, useQuery, useInfiniteQuery} from 'react-query';

import {updateAlbum} from '../../hooks/mutateAlbum';
import {getAlbumById} from '../../hooks/useAlbum';
import {getPhotos, queryPhotosWithFilter} from '../../hooks/usePhotos';
import isJsonString from '../../utils/utils';


const {TextArea} = Input;
const {Title} = Typography;


const IconText = ({icon, text, photoKey, dataPath, onClickHandler}) => (
  <div data-id={photoKey} data-path={dataPath} onClick={onClickHandler} >
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  </div>
);

const layout = {
  layout: 'vertical',
};


const BUCKET = process.env.REACT_APP_BUCKET_URL;

const UpdateAlbum = (props) => {
  const [form] = Form.useForm();
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [filter, setFilter] = useState('');

  const [cover, setCover] = useState({});

  const [isQueryMode, setIsQueryMode] = useState(false);
  const [queryData, setQueryData] = useState([]);

  const {data: photosData, isLoading: loadingPhotos, fetchNextPage, hasNextPage} = useInfiniteQuery(
      'photos',
      getPhotos,
      {
        retry: false,
        getNextPageParam: (lastPage) => lastPage.nextToken ?? undefined,
        select: (data) => {
          const pages = data.pages.flatMap((x) => x);
          return {
            pages: pages.map((page) => {
              return {nextToken: page.nextToken, photos: page.photos.filter((photo) => photo.path.includes(filter))};
            }),
            pageParams: data.pageParams,
          };
        },
      },
  );

  const {isLoading, isError, data: albumData} = useQuery(
      ['album', props.album],
      () => getAlbumById(props.album),
      {
        retry: false,
        onSuccess: (data) => {
          if (isJsonString(data.cover)) {
            setCover(JSON.parse(data.cover));
          }
          setSelectedPhotos(data.photos);
        },
      },
  );

  const updateMutation = useMutation({
    mutationFn: updateAlbum,
    onSuccess: () => {
      props.endUpdateCallback();
    },
  });

  const handleSubmit = async (form) => {
    const title = form.album_title;
    const description = form.album_description;

    const session = await Auth.currentSession();
    const headers = {
      'Authorization': session.getAccessToken().getJwtToken(),
    };

    updateMutation.mutate({
      albumId: props.album,
      title,
      description,
      photos: selectedPhotos,
      cover: JSON.stringify(cover),
      headers,
    });
  };

  const handleSelectPhoto = (event) => {
    const photoId = event.currentTarget.dataset.id;
    if (selectedPhotos.includes(photoId)) {
      setSelectedPhotos(selectedPhotos.filter((s) => s !== photoId));
    } else {
      setSelectedPhotos([...selectedPhotos, photoId]);
    }
  };

  const handleSelectCover = (event) => {
    setCover({id: event.currentTarget.dataset.id, path: event.currentTarget.dataset.path});
  };

  const handleFilterImages = (event) => {
    setFilter(event.currentTarget.value);
  };

  const handleQuery = async () => {
    const session = await Auth.currentSession();
    const headers = {
      'Authorization': session.getAccessToken().getJwtToken(),
    };
    const result = await queryPhotosWithFilter(filter, headers);
    setIsQueryMode(true);
    setQueryData(result);
  };

  const handleSelectAll = () => {
    setSelectedPhotos(photosData.pages.flatMap((p) => p.photos.flatMap((p) => p.key)));
  };

  const loadMore =
    !loadingPhotos ? (
      <div
        style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button disabled={!hasNextPage} onClick={() => fetchNextPage()}>Loading more</Button>
      </div>
    ) : null;

  if (isLoading || loadingPhotos) {
    return (<p>Loading</p>);
  }

  if (isError) {
    return (<p>Error</p>);
  }

  return (
    <>
      <Form {...layout} form={form} name="control-hooks" onFinish={handleSubmit}>
        <Form.Item>
          <Space>
            <Button variant="secondary" onClick={props.endUpdateCallback}>Close</Button>
            <Button type="primary" htmlType="submit">
            Update
            </Button>
          </Space>
        </Form.Item>
        <Form.Item name="album_title" label="Title" rules={[{required: true}]} initialValue={albumData.title} >
          <Input />
        </Form.Item>
        <Form.Item name="album_description" label="Description" rules={[{required: true}]} initialValue={albumData.description}>
          <TextArea rows={4} />
        </Form.Item>
      </Form>

      <Title level={2}>Photos to add in album:</Title>
      <Space>
        <Input placeholder="Filter images" onChange={handleFilterImages}/>
        <Button type='primary' onClick={handleQuery} disabled={filter === ''}>Query</Button>
        <Switch onChange={() => setIsQueryMode(!isQueryMode)} checkedChildren='Mode' checked={isQueryMode} disabled={filter === ''} />
        <Button type='primary' onClick={handleSelectAll} disabled={filter === ''} >Select all</Button>
      </Space>
      <Divider />
      <div style={{width: '100%'}}>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 2,
            lg: 3,
            xl: 4,
            xxl: 6,
          }}
          dataSource={isQueryMode ? queryData : photosData.pages.flatMap((page) => page.photos)}
          loadMore={loadMore}
          renderItem={(photo) => (
            <List.Item>
              <Card
                cover={
                  <div style={{position: 'relative'}}>
                    <img style={{objectFit: 'cover', width: '100%', height: '250px'}} src={`${BUCKET}/${photo.smallPath}`} />
                    { selectedPhotos.includes(photo.key) ? <CloseSquareFilled style={{fontSize: '36px', position: 'absolute', top: 5, right: 5}}/> : null }
                    { cover.id === photo.key ? <FileImageFilled style={{fontSize: '36px', position: 'absolute', top: 5, left: 5}}/> : null }
                  </div>}
                actions={[
                  <IconText key='select-photo' icon={CheckCircleFilled} text="Add to album" photoKey={photo.key} onClickHandler={handleSelectPhoto}/>,
                  <IconText key='select-cover' icon={CheckCircleFilled} text="Select as cover" photoKey={photo.key} dataPath={photo.path} onClickHandler={handleSelectCover} />,
                ]}
              >
                {photo.path}
              </Card>
            </List.Item>
          )
          }
        />
      </div>
    </>
  );
};

export default UpdateAlbum;


