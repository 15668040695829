/**
 * Check if String is Json
 * @param {string} str
 * @return {*}
 */
function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export default isJsonString;
