import React from 'react';

import {List} from 'antd';

import Album from './Album';

const Albums = (props) => {
  const {albumsList, isPlaceholderData} = props;
  const loading = props.loading || false;

  return (
    <React.Fragment>
      {albumsList.length > 0 && <List
        grid={{
          column: 3,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 3,
          xxl: 3,
          gutter: 16,
        }}
        dataSource={albumsList}
        renderItem={(item) => (
          <List.Item>
            <Album
              albumId={item.id}
              cover={item.cover}
              title={item.title}
              description={item.description}
              loading={loading}
              isPlaceholderData={isPlaceholderData}
            />
          </List.Item>
        )}
      />}
    </React.Fragment>
  );
};

export default Albums;
