import React from 'react';

import {Breadcrumb, Layout} from 'antd';
import {Content, Footer} from 'antd/lib/layout/layout';
import {Link, withRouter} from 'react-router-dom';

import classes from './GlobalLayout.module.css';
import MainHeader from './MainHeader';

/**
 * Capitalize first letter
 * @param {string} string
 * @return {string}
 */
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const GlobalLayout = withRouter((props) => {
  const {location} = props;
  const pathSnippets = location.pathname.split('/').filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    let text = pathSnippets[index];
    if (props.breadcrumbProps != false) {
      if (props.breadcrumbProps.id === text) {
        text = props.breadcrumbProps.title;
      }
    }
    return (
      <Breadcrumb.Item key={url}>
        <Link to={url}>{capitalizeFirstLetter(text)}</Link>
      </Breadcrumb.Item>
    );
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="accueil">
      <Link to="/">Accueil</Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  const breadcrumbEl = <Breadcrumb className={classes.breadcrumb}>{breadcrumbItems}</Breadcrumb>;
  const footer = <Footer style={{textAlign: 'center'}}>Guillaume Desrumaux © 2023</Footer>;
  let content = <>
    <Content className={classes.container} >
      {breadcrumbEl}
      {props.children}
    </Content>
    {footer}
  </>;

  let layoutSize = '';
  if (breadcrumbItems.length === 1) {
    layoutSize = classes['layout-main'];
    content = <Content className={classes.content}>{props.children}</Content>;
  }

  return (
    <Layout className={`${classes.layout} ${layoutSize}`}>
      <MainHeader currentUrl={pathSnippets} />
      {content}
    </Layout>
  );
});

export default GlobalLayout;
