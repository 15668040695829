import React from 'react';

import ReactDOM from 'react-dom';
import {QueryClient, QueryClientProvider} from 'react-query';
import './index.css';
import {BrowserRouter} from 'react-router-dom';

import App from './App';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </QueryClientProvider>,
    document.getElementById('root'),
);
