import React from 'react';

const NotFound = () => {
  return (
    <div className='centered'>
      <p>Page not found!</p>
    </div>
  );
};

export default NotFound;
