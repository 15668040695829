import React, {useState} from 'react';

import {UploadOutlined} from '@ant-design/icons';
import {S3Client, PutObjectCommand, DeleteObjectCommand} from '@aws-sdk/client-s3';
import {Upload, Button, Space, Modal, Divider, message, Table, Input, Image as AntImage} from 'antd';
import {Auth} from 'aws-amplify';
import {useMutation, useQueryClient, useInfiniteQuery} from 'react-query';

import {createPhoto, deletePhoto} from '../../hooks/mutatePhoto';
import {getPhotos} from '../../hooks/usePhotos';
import CursorTable from './CursorTable';

const REGION = process.env.REACT_APP_AWS_REGION;
const BUCKET_NAME = process.env.REACT_APP_SOURCE_BUCKET;
const BUCKET_URL = process.env.REACT_APP_BUCKET_URL;

const AdminPhoto = () => {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [filter, setFilter] = useState('');


  const [showPic, setShowPic] = useState(false);
  const [currentPic, setCurrentPic] = useState('');

  const queryClient = useQueryClient();

  const {data, isLoading, isSuccess, isFetchingNextPage, fetchNextPage, hasNextPage} = useInfiniteQuery(
      'photos',
      getPhotos,
      {
        retry: false,
        getNextPageParam: (lastPage, allPages) => lastPage.nextToken ?? undefined,
        select: (data) => {
          const pages = data.pages.flatMap((x) => x);
          return {
            pages: pages.map((page) => {
              return {nextToken: page.nextToken, photos: page.photos.filter((photo) => photo.path.includes(filter))};
            }),
            pageParams: data.pageParams,
          };
        },
      },
  );

  const creationMutation = useMutation({
    mutationFn: createPhoto,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['photos']});
    },
  });

  const deletionMutation = useMutation({
    mutationFn: deletePhoto,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['photos']});
    },
  });

  const handleOpenImage = async (event) => {
    setCurrentPic(event.target.innerText);
    setShowPic(true);
  };

  const handleUpload = async () => {
    const today = new Date();
    const creds = await Auth.currentCredentials();
    const s3 = new S3Client({
      region: REGION,
      credentials: Auth.essentialCredentials(creds),
    });

    const session = await Auth.currentSession();
    const headers = {
      'Authorization': session.getAccessToken().getJwtToken(),
    };

    setUploading(true);
    await fileList.forEach(async (file) => {
      // Initiate the JavaScript Image object.
      const image = new Image();
      // Set the Base64 string return from FileReader as source.
      image.src = URL.createObjectURL(file);
      // Validate the File Height and Width.
      let imageHeight = 0;
      let imageWidth = 0;
      const loadImage = async (img) => {
        return new Promise((resolve, reject) => {
          img.onload = async () => {
            imageHeight = image.height;
            imageWidth = image.width;
            resolve(true);
          };
        });
      };
      await loadImage(image);
      const albumPhotosKey = `${(today.getUTCMonth()+1)}/${today.getUTCDate()}/`;
      const fileName = file.name;
      const photoKey = albumPhotosKey + fileName;

      const uploadParams = {
        Bucket: BUCKET_NAME,
        Key: photoKey,
        Body: file,
      };

      const photo = {
        fileName,
        photoKey,
        imageWidth,
        imageHeight,
      };

      try {
        creationMutation.mutate({
          photo,
          headers,
        });
        await s3.send(new PutObjectCommand(uploadParams));
        message.success('Succesfully uploaded: ' + fileName);
      } catch (err) {
        message.error('There was an error uploading your photo: ' + err.message);
      }
    });
    setUploading(false);
    setFileList([]);
  };

  const handleDeletion = async ({currentTarget}) => {
    const creds = await Auth.currentCredentials();
    const session = await Auth.currentSession();
    const headers = {
      'Authorization': session.getAccessToken().getJwtToken(),
    };
    const s3 = new S3Client({
      region: REGION,
      credentials: Auth.essentialCredentials(creds),
    });

    const path = currentTarget.getAttribute('data-path');
    const id = currentTarget.getAttribute('data-photo-id');

    const deleteParams = {
      Bucket: BUCKET_NAME,
      Key: path,
    };

    try {
      await s3.send(new DeleteObjectCommand(deleteParams));
      deletionMutation.mutate({photoId: id, headers});
      message.success('Succesfully deleted: ' + path);
    } catch (err) {
      message.error('There was an error deleting your photo: ' + err.message);
    }
  };

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.preview) {
      file.preview = URL.createObjectURL(file);
    }
    setPreviewImage(file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name);
  };

  const handleFilterImages = (event) => {
    setFilter(event.currentTarget.value);
  };

  const columns = [
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Path',
      dataIndex: 'path',
      key: 'path',
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        const nameA = a.path.toUpperCase(); // ignore upper and lowercase
        const nameB = b.path.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
      },
      render: (_, record) => (
        <Space size="middle" >
          <Button type="primary" onClick={handleOpenImage}>
            {record.path}
          </Button>
        </Space>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button type="danger" data-photo-id={record.key} data-path={record.path} onClick={handleDeletion}>Delete</Button>
        </Space>
      ),
    },
  ];

  const uploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      const newList = fileList;
      file.thumbUrl = URL.createObjectURL(file);
      newList.push(file);
      setFileList(newList);
      return false;
    },
    onPreview: handlePreview,
    listType: 'picture',
    fileList: fileList,
    multiple: true,
    maxCount: 15,
  };

  return (
    <>
      <AntImage
        style={{
          display: 'none',
        }}
        src={`${BUCKET_URL}/${currentPic}`}
        preview={{
          visible: showPic,
          src: `${BUCKET_URL}/${currentPic}`,
          onVisibleChange: (value) => {
            setShowPic(value);
          },
        }}
      />
      <Upload {...uploadProps}>
        <Button icon={<UploadOutlined />}>Upload</Button>
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
      <Button
        type="primary"
        onClick={handleUpload}
        // disabled={fileList.length === 0}
        loading={uploading}
        style={{
          marginTop: 16,
        }}
      >
        {uploading ? 'Uploading' : 'Start Upload'}
      </Button>
      <Divider />
      <Button type='primary' style={{marginBottom: '15px'}} disabled={!hasNextPage} onClick={() => fetchNextPage()}>Load more</Button>
      <Input placeholder="Filter images" style={{marginBottom: '15px'}} onChange={handleFilterImages}/>
      <CursorTable
        loading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        dataSource={isSuccess ? data : {pages: [], pageParams: []}}
        bordered
        pagination={{
          pageSize: 20,
        }}
        onNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        columns={columns} />
    </>
  );
};

export default AdminPhoto;


