import React, {useState, useEffect, useRef} from 'react';

import classes from './HomePage.module.css';


const photos = [
  {
    name: 'Kutná Hora, République Tchèque',
    url: 'https://d3h0yhjftcse3j.cloudfront.net/5/18/KUTNA-HORA-14.jpg',
  },
  {
    name: 'Linz, Autriche',
    url: 'https://d3h0yhjftcse3j.cloudfront.net/5/29/LINZ-19.jpg',
  },
  {
    name: 'Veltrusy, République Tchèque',
    url: 'https://d3h0yhjftcse3j.cloudfront.net/5/18/VELTRUSY-22.jpg',
  },
  {
    name: 'Vyšší Brod, République Tchèque',
    url: 'https://d3h0yhjftcse3j.cloudfront.net/5/29/VYSSI_BROD-16.jpg',
  },
  {
    name: 'Marseille, France',
    url: 'https://d3h0yhjftcse3j.cloudfront.net/1/19/MARSEILLE-23.jpg',
  },
  {
    name: 'Bari, Italie',
    url: 'https://d3h0yhjftcse3j.cloudfront.net/12/11/PUGLIA-90.jpg',
  },
  {
    name: 'Marseille, France',
    url: 'https://d3h0yhjftcse3j.cloudfront.net/1/19/MARSEILLE-10.jpg',
  },
  {
    name: 'Antibes, France',
    url: 'https://d3h0yhjftcse3j.cloudfront.net/12/3/ANTIBES-12.jpg',
  },
  {
    name: 'Lipno nad Vltavou, République Tchèque',
    url: 'https://d3h0yhjftcse3j.cloudfront.net/5/29/LIPNO_NAD_VLTAVOU-42.jpg',
  },
  {
    name: 'Polignano a Mare, Italie',
    url: 'https://d3h0yhjftcse3j.cloudfront.net/12/11/PUGLIA-7.jpg',
  },
  {
    name: 'Kutná Hora, République Tchèque',
    url: 'https://d3h0yhjftcse3j.cloudfront.net/5/18/KUTNA-HORA-33.jpg',
  },
  {
    name: 'Monopoli, Italie',
    url: 'https://d3h0yhjftcse3j.cloudfront.net/12/11/PUGLIA-24.jpg',
  },
  {
    name: 'Trani, Italie',
    url: 'https://d3h0yhjftcse3j.cloudfront.net/12/11/PUGLIA-40.jpg',
  },
  {
    name: 'Vyšší Brod, République Tchèque',
    url: 'https://d3h0yhjftcse3j.cloudfront.net/5/29/VYSSI_BROD-8.jpg',
  },
  {
    name: 'Marseille, France',
    url: 'https://d3h0yhjftcse3j.cloudfront.net/1/19/MARSEILLE-27.jpg',
  },
  {
    name: 'Marseille, France',
    url: 'https://d3h0yhjftcse3j.cloudfront.net/1/19/MARSEILLE-5.jpg',
  },
  {
    name: 'Linz, Autriche',
    url: 'https://d3h0yhjftcse3j.cloudfront.net/5/29/LINZ-4.jpg',
  },
  {
    name: 'Lipno nad Vltavou, République Tchèque',
    url: 'https://d3h0yhjftcse3j.cloudfront.net/5/29/LIPNO_NAD_VLTAVOU-43.jpg',
  },
];

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};
shuffleArray(photos);

const HomePage = () => {
  const [animation, setAnimation] = useState('stop');
  const [textAnimation, setTextAnimation] = useState('appear');

  const [currentImageIdx, setCurrentImageIdx] = useState(0);
  const [nextImageIdx, setNextImageIdx] = useState(1);

  const intervalRef = useRef(null);

  const getSecondImageIndex = (currIndex, dir) => {
    if (dir === 'stop') {
      return currIndex;
    } else if (dir === 'right') {
      return (currIndex + 1 + photos.length) % photos.length;
    } else if (dir === 'left') {
      return (currIndex - 1 + photos.length) % photos.length;
    }
  };

  const onNext = () => {
    if (animation === 'stop') {
      setAnimation('right');
      setTextAnimation('disappear');
      // Clear the ongoing interval
      clearInterval(intervalRef.current);
      // Start a new interval after the manual action
      startInterval();
    }
  };

  const onPrevious = () => {
    if (animation === 'stop') {
      setAnimation('left');
      setTextAnimation('disappear');
      // Clear the ongoing interval
      clearInterval(intervalRef.current);
      // Start a new interval after the manual action
      startInterval();
    }
  };

  const onTransitionEnd = () => {
    const prevNext = nextImageIdx;
    const prevAnimation = animation;
    setAnimation('stop');
    setTextAnimation('appear');
    setCurrentImageIdx(prevNext);
    setNextImageIdx(getSecondImageIndex(prevNext, prevAnimation));
  };

  const onTransitionEndText = (prevAnimation) => {
    if (prevAnimation === 'disappear') {
      setTextAnimation('appear');
    }
  };

  const handleThumbClick = (e) => {
    e.preventDefault();
    const imgSrc = e.target.src;
    const idx = photos.findIndex((photo) => photo['url'] === imgSrc);
    if (idx !== -1) {
      setAnimation('right');
      setTextAnimation('disappear');
      setNextImageIdx(idx);
      // Clear the ongoing interval
      clearInterval(intervalRef.current);
      // Start a new interval after the manual action
      startInterval();
    }
  };

  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      onNext();
    }, 5000);
  };

  // UseEffect to automatically change the image
  useEffect(() => {
    startInterval();

    return () => {
      // Clear the interval when the component is unmounted or when a manual action occurs
      clearInterval(intervalRef.current);
    };
  }, []);

  const thumbs = photos.map((photo, index) => {
    return (
      <div key={index} onClick={handleThumbClick} className={classes['thumb-item']}>
        <img src={photo.url} />
      </div>
    );
  });

  const previousButton = currentImageIdx > 0 ? (<div className={`${classes.btn} ${classes.prevBtn}`} onClick={onPrevious}></div>) : '';

  const nextButton = currentImageIdx < photos.length - 1 ? (<div className={`${classes.btn} ${classes.nextBtn}`} onClick={onNext}></div>) : '';

  return (
    <div className={classes.box}>
      <div className={classes['image-container']}>
        <div className={classes['cover-image-container']}>
          <img
            className={`${classes.image} ${classes.firstImage}`}
            src={photos[currentImageIdx].url}
            data-animate={animation}
            onTransitionEnd={onTransitionEnd}
          />
          <img
            className={classes.image}
            src={photos[nextImageIdx].url}
          />
          <span
            data-animate={textAnimation}
            onTransitionEnd={onTransitionEndText}
            className={classes['image-name']}
          >
            {photos[currentImageIdx].name}
          </span>
          {previousButton}
          {nextButton}
        </div>
      </div>
      <div className={classes.thumbs}>
        <div className={classes['thumb-container']}>
          {thumbs}
        </div>
      </div>
    </div>
  );
};

export default HomePage;
