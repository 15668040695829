import React, {useState} from 'react';

import {Table, Space, Row, Col} from 'antd';

import CursorPagination from './CursorPagination';

const CursorTable = (props) => {
  const {pagination} = props;
  const {dataSource, onNextPage, hasNextPage, ...newProps} = props;
  const {position = 'bottomRight', size = 'default'} = pagination || {};
  const [page, setPage] = useState(0);

  const [verticalPosition, alignmentPosition] =
    position.match(/[A-Z]?[a-z]+/g);

  const justifyPosition = {
    left: 'start',
    center: 'center',
    right: 'end',
  }[alignmentPosition.toLowerCase()];

  const onNextPageChange = () => {
    if (hasNextPage) {
      onNextPage();
      setPage(page + 1);
    }
  };

  const onPreviousPageChange = () => {
    if (page !== 0) {
      setPage(page -1);
    }
  };

  console.log(dataSource, page, props.isFetchingNextPage, props.loading, props.isFetchingNextPage);

  return (
    <Space direction="vertical" style={{width: '100%'}}>
      {verticalPosition === 'top' &&
        <Row justify={justifyPosition}>
          <Col>
            <CursorPagination
              firstPage={page === 0}
              onPreviousPageChange={onPreviousPageChange}
              onNextPageChange={onNextPageChange}
              hasNextPage={hasNextPage || dataSource.pages.length > page}
              size={size}/>
          </Col>
        </Row>}
      <Table {...newProps} dataSource={ !props.loading && !props.isFetchingNextPage && dataSource.pages[page] ? dataSource.pages[page]['photos'] : [] } pagination={{position: ['none', 'none']}} />
      {verticalPosition === 'bottom' &&
        <Row justify={justifyPosition}>
          <Col>
            <CursorPagination
              firstPage={page === 0}
              onPreviousPageChange={onPreviousPageChange}
              onNextPageChange={onNextPageChange}
              hasNextPage={hasNextPage || dataSource.pages.length > page}
              size={size}/>
          </Col>
        </Row>}
    </Space>
  );
};

export default CursorTable;
