import React, {useEffect, useState} from 'react';

import {LeftOutlined, RightOutlined} from '@ant-design/icons';
import {Button, Space} from 'antd';

const CursorPagination = ({firstPage, hasNextPage, onPreviousPageChange, onNextPageChange, size}) => {
  /* Button style changes based on size */
  const [buttonStyle, setButtonStyle] = useState({});

  useEffect(() => {
    switch (size) {
      case 'small':
        setButtonStyle({paddingLeft: '4px', paddingRight: '4px', borderColor: 'transparent'});
        break;
      case 'default':
      default:
        setButtonStyle({paddingLeft: '7px', paddingRight: '7px'});
        break;
    }
  }, [size]);

  /* Display forward and back buttons */
  return (
    <Space direction="horizontal">
      <Button
        tabIndex={-1}
        size={size}
        disabled={firstPage}
        style={buttonStyle}
        onClick={onPreviousPageChange}>
        <LeftOutlined/>
      </Button>
      <Button
        tabIndex={-1}
        size={size}
        disabled={!hasNextPage}
        style={buttonStyle}
        onClick={onNextPageChange}>
        <RightOutlined/>
      </Button>
    </Space>
  );
};


export default CursorPagination;
