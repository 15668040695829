import React from 'react';

import {Button, Space, Input, Form, Table, Tag} from 'antd';
import {Auth} from 'aws-amplify';
import {useMutation, useQueryClient} from 'react-query';

import {createAlbum, deleteAlbum, switchAlbumVisibility} from '../../hooks/mutateAlbum';
import {usePrivateAlbums} from '../../hooks/useAlbums';


const {TextArea} = Input;

const layout = {
  labelCol: {span: 8},
  wrapperCol: {span: 16},
};


const AdminAlbum = (props) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const {data, isLoading} = usePrivateAlbums();

  const creationMutation = useMutation({
    mutationFn: createAlbum,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({queryKey: ['privateAlbums']});
    },
  });

  const switchVisibilityMutation = useMutation({
    mutationFn: switchAlbumVisibility,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({queryKey: ['privateAlbums']});
    },
  });

  const deletionMutation = useMutation({
    mutationFn: deleteAlbum,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({queryKey: ['privateAlbums']});
    },
  });

  const handleSubmit = async (form) => {
    const title = form.album_title;
    const description = form.album_description;
    const session = await Auth.currentSession();
    const headers = {
      'Authorization': session.getAccessToken().getJwtToken(),
    };
    creationMutation.mutate({title, description, headers});
  };

  const handleDeletion = async ({currentTarget}) => {
    const session = await Auth.currentSession();
    const headers = {
      'Authorization': session.getAccessToken().getJwtToken(),
    };
    deletionMutation.mutate({albumId: currentTarget.getAttribute('value'), headers});
  };

  const handleUpdate = async ({currentTarget}) => {
    props.updateAlbumCallback(currentTarget.getAttribute('value'));
  };

  const handleIsPublic = async ({currentTarget}) => {
    const session = await Auth.currentSession();
    const headers = {
      'Authorization': session.getAccessToken().getJwtToken(),
    };
    const isPublic = !(currentTarget.getAttribute('data-visibility') === 'true');
    switchVisibilityMutation.mutate({albumId: currentTarget.getAttribute('value'), isPublic, headers});
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Public',
      dataIndex: 'isPublic',
      key: 'isPublic',
      render: (_, record) => {
        let color;
        if (record.isPublic) {
          color = 'green';
        } else {
          color = 'red';
        }
        return (
          <Tag color={color}>
            {record.isPublic.toString().toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button type="primary" value={record.id} onClick={handleUpdate}>
            Update
          </Button>
          <Button type="danger" value={record.id} onClick={handleDeletion}>
            Delete
          </Button>
          <Button type="primary" style={{background: 'green', borderColor: 'green'}} value={record.id} data-visibility={record.isPublic} onClick={handleIsPublic}>
            Switch visibility
          </Button>
        </Space>
      ),
    },
  ];


  return (
    <>
      <Form {...layout} form={form} name="control-hooks" onFinish={handleSubmit}>
        <Form.Item name="album_title" label="Title" rules={[{required: true}]}>
          <Input />
        </Form.Item>
        <Form.Item name="album_description" label="Description" rules={[{required: true}]}>
          <TextArea rows={4} />
        </Form.Item>
        <Form.Item wrapperCol={{offset: 8, span: 16}}>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      <Table loading={isLoading} dataSource={data} bordered columns={columns} rowKey='id' />
    </>
  );
};

export default AdminAlbum;


