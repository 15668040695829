import React from 'react';

import {RightOutlined} from '@ant-design/icons';
import {Card, Button, Col, Row} from 'antd';
import {Link, useRouteMatch} from 'react-router-dom';

import ImageHover from '../UI/ImageHover';

const {Meta} = Card;

const sizeMapping = [
  {
    path: 'large',
    width: 700,
  },
  {
    path: 'medium',
    width: 500,
  },
  {
    path: 'small',
    width: 300,
  },
];

const PHOTO_URL = process.env.REACT_APP_BUCKET_URL;

const Album = (props) => {
  const imageLoading = props.loading;
  const isPlaceholderData = props.isPlaceholderData;
  const match = useRouteMatch();

  const coverPath = props.cover.path;

  const coverImg = imageLoading ? '' : `${PHOTO_URL}/${coverPath}`;

  const coverSrcSet = sizeMapping.map((size) => {
    const coverPathSplit = coverPath.split('.');
    return `${PHOTO_URL}/${coverPathSplit[0]}-${size.path}.${coverPathSplit[1]} ${size.width}w`;
  });
  const coverSizes = '(min-width: 750px) 350px, (min-width: 1400px) 460px, 100vw';
  const cover = <ImageHover textOverlay={props.title} linkOverlay={`${match.url}/${props.albumId}`} textLinkOverlay="Voir les photos" imageAlt="example" imageUrl={coverImg} imageSrcSet={coverSrcSet.join(',')} imageSizes={coverSizes} />;


  return (
    <Card
      loading={imageLoading}
      cover={isPlaceholderData ? null : cover}
    >
      <Row justify="center" align="middle">
        <Col span={8}><Meta title={props.title} description={props.description} style={{float: 'left'}} /></Col>
        <Col span={8} offset={8}>
          <Link to={`${match.url}/${props.albumId}`}>
            <Button type="primary" icon={<RightOutlined />} size='large' style={{float: 'right'}} >
          Voir les photos
            </Button>
          </Link>
        </Col>
      </Row>
    </Card>
  );
};

export default Album;


