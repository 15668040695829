import React from 'react';

import {Helmet} from 'react-helmet';

import Albums from '../components/Album/Albums';
import {useAlbums} from '../hooks/useAlbums';

const AlbumsPage = () => {
  const {isPlaceholderData, isLoading, isError, data, error} = useAlbums();

  return (
    <div>
      <Helmet>
        <title>Albums - Desrumaux</title>
        <meta
          name="description"
          content="Page whith list of all albums available."
        />
      </Helmet>
      {isError ? (
        <span>Error: {error.message}</span>
      ) : (
        <Albums albumsList={data} loading={isLoading || isPlaceholderData} isPlaceholderData={isPlaceholderData} />
      )}
    </div>);
};

export default AlbumsPage;
