import {GraphQLClient, gql} from 'graphql-request';

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const createAlbum = async (vars) => {
  const {headers} = vars;
  const graphQLClient = new GraphQLClient(endpoint, {
    headers,
  });

  const mutation = gql`
      mutation createAlbum($title: String!, $description: String!){
        createAlbum(album: {title: $title, description: $description}) {
            id
        }
      }`;

  const data = await graphQLClient.request(mutation, vars);

  return data;
};

const switchAlbumVisibility = async (vars) => {
  const {headers} = vars;
  const graphQLClient = new GraphQLClient(endpoint, {
    headers,
  });

  const mutation = gql`
      mutation setPublicAlbum($albumId: String!, $isPublic: Boolean!){
        setPublicAlbum(albumId: $albumId, isPublic: $isPublic) {
          id
          isPublic
        }
      }`;

  const data = await graphQLClient.request(mutation, vars);

  return data;
};


const deleteAlbum = async (vars) => {
  const {headers} = vars;
  const graphQLClient = new GraphQLClient(endpoint, {
    headers,
  });

  const mutation = gql`
      mutation deleteAlbum($albumId: String!){
        deleteAlbum(albumId: $albumId)
      }`;

  const data = await graphQLClient.request(mutation, vars);

  return data;
};

const updateAlbum = async (vars) => {
  const {headers} = vars;
  const graphQLClient = new GraphQLClient(endpoint, {
    headers,
  });

  const mutation = gql`
      mutation updateAlbum($albumId: ID!, $title: String!, $description: String!, $cover: String!, $photos: [String!]!){
        updateAlbum(album: {id: $albumId, title: $title, description: $description, cover: $cover, photos: $photos}) {
          id
        }
      }`;

  const data = await graphQLClient.request(mutation, vars);
  return data;
};


/**
 *
 * @return {*}
 */
export {createAlbum, deleteAlbum, updateAlbum, switchAlbumVisibility};
