import {Auth} from 'aws-amplify';
import {GraphQLClient, gql} from 'graphql-request';

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const getPhotos = async ({pageParam}) => {
  const session = await Auth.currentSession();

  const headers = {
    'Authorization': session.getAccessToken().getJwtToken(),
  };

  const graphQLClient = new GraphQLClient(endpoint, {
    headers,
  });


  const vars = {
    limit: 20,
  };

  if (pageParam !== '') {
    vars.nextToken = pageParam;
  }

  const data = await graphQLClient.request(
      gql`
      query ($limit: Int, $nextToken: String){
        allPhotos(limit: $limit, nextToken: $nextToken) {
          photos {
            id
            path
          }
          nextToken
        }
      }
      `,
      vars,
  );
  const transformedPhotos = data.allPhotos.photos.map(function(photo) {
    const pathSplit = photo.path.split('.');
    return {
      key: photo.id,
      path: photo.path,
      smallPath: `${pathSplit[0]}-small.${pathSplit[1]}`,
      mediumPath: `${pathSplit[0]}-medium.${pathSplit[1]}`,
      largePath: `${pathSplit[0]}-large.${pathSplit[1]}`,
    };
  });
  return {photos: transformedPhotos, nextToken: data.allPhotos.nextToken};
};


const queryPhotosWithFilter = async (filter, headers) => {
  const graphQLClient = new GraphQLClient(endpoint, {
    headers,
  });

  const data = await graphQLClient.request(
      gql`
      query ($filter: String!) {
        queryPhotoByName(filter: $filter) {
          photos {
            id
            path
          }
        }
      }
      `,
      {filter},
  );
  return data.queryPhotoByName.photos.map(function(photo) {
    const pathSplit = photo.path.split('.');
    return {
      key: photo.id,
      path: photo.path,
      smallPath: `${pathSplit[0]}-small.${pathSplit[1]}`,
      mediumPath: `${pathSplit[0]}-medium.${pathSplit[1]}`,
      largePath: `${pathSplit[0]}-large.${pathSplit[1]}`,
    };
  });
};

export {getPhotos, queryPhotosWithFilter};
